import Chart from "chart.js";
import "chartjs-plugin-datalabels";
import React, { useEffect, useRef, useState } from "react";
import { getChartData } from "./chartDatahelper";

const LineChart = ({ data }) => {
  const [chartStyles, setChartStyles] = useState(null);
  const [chartData, setChartData] = useState(null);
  const chartRef = useRef(null);
  let myChart;

  useEffect(() => {
    if (chartRef.current && chartStyles) {
      myChart = new Chart(chartRef.current, chartStyles);
    }
    return () => {
      myChart && myChart.destroy();
    };
  }, [chartStyles]);

  useEffect(() => {
    setChartData(getChartData(data));
  }, [data]);

  useEffect(() => {
    if (chartData) {
      const averages = chartData.average;
      const minValue = Math.min(...averages);
      const maxValue = 11;
      let stepSize = (maxValue - minValue) / 10;
      if (stepSize < 0.1) {
        stepSize = 0.1;
      }

      // Gradiente para a linha
      const ctx = chartRef.current.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(255, 255, 255, 0.44)");
      gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

      setChartStyles({
        type: "line",
        data: {
          labels: chartData.labels,
          datasets: [
            {
              label: "Valor médio",
              data: averages,
              backgroundColor: gradient,
              borderColor: gradient,
              borderWidth: 3,
              hoverRadius: 2,
              lineTension: 0,
              pointBackgroundColor: "#000338",
              pointBorderColor: "#FFFFFF",
              pointBorderWidth: 2,
              pointHoverBorderWidth: 7,
              pointRadius: 6,
              spanGaps: true
            }
          ]
        },
        options: {
          title: {
            display: false,
            text: 'VALORES CONSOLIDADOS MENSAIS',
            fontColor: "#8081a8",
            fontSize: 16,
            fontFamily: "Roboto-Regular",
          },
          plugins: {
            datalabels: {
              display: (context) => context.dataset.label === "Valor médio",
              align: 'top',
              color: '#FFFFFF',
              font: {
                size: 17,
                weight: 'bold',
              },
              formatter: (value) => parseFloat(value.toFixed(2)),
            }
          },
          legend: {
            display: true,
            position: "bottom",
            align: 'start',
            labels: {
              usePointStyle: true,
              fontColor: "#FFFFFF",
              fontSize: 16,
              fontFamily: "Roboto",
              boxWidth: 10,
              boxHeight: 9,
              padding: 40,
              generateLabels: (chart) => {
                const original = Chart.defaults.global.legend.labels.generateLabels;
                const labels = original(chart);

                labels.forEach((label) => {
                  label.lineWidth = 3;
                  label.fontSize = 6;
                  label.color = "#FFFFFF";
                  label.pointStyle = 'circle';
                  label.fillStyle = "transparent";
                });

                return labels;
              }
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "transparent",
                  display: false,
                  drawBorder: false,
                  drawTicks: false,
                },
                ticks: {
                  autoSkip: true,
                  maxRotation: 90,
                  minRotation: 0,
                  padding: 15,
                  fontColor: "#FFFFFF",
                  fontSize: 16,
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  color: "transparent",
                  display: false,
                  drawBorder: false,
                  drawTicks: false,
                },
                ticks: {
                  fontColor: "transparent",
                  fontSize: 14,
                  padding: 32,
                  stepSize: stepSize,
                  suggestedMax: maxValue,
                  suggestedMin: minValue,
                  callback: function (value) {
                    return value.toFixed(2);
                  }
                }
              }
            ]
          },
          maintainAspectRatio: false,
          responsive: true
        }
      });
    }
  }, [chartData]);

  return (
    <canvas ref={chartRef} style={{ borderRadius: '15px' }} />
  );
};

export default LineChart;
